const contractABI = require("../contract-abi.json");
const contractAddress = process.env.REACT_APP_CONTRACT_ID;
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
var provider = createAlchemyWeb3(alchemyKey);

if (process.env.NODE_ENV === "development") {
  const Web3 = require('web3');
  provider = new Web3(window.ethereum);
}

const web3 = provider;

export const connectWallet = async () => {
  if (!window.ethereum) {
    return {
      status: "🦊 You need to install <a href='https://metamask.io/download.html' rel='noreferrer' target='_blank'>Metamask</a>",
    }
  }

  try {
    const addressArray = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    const obj = {
      address: addressArray[0],
    };

    return obj;
  } catch (err) {
    return {
      address: "",
      status: "😥 " + err.message,
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (!window.ethereum) {
    return {
      status: "🦊 You need to install <a href='https://metamask.io/download.html' rel='noreferrer' target='_blank'>Metamask</a>",
    }
  }

  try {
    const addressArray = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (addressArray.length > 0) {
      return {
        address: addressArray[0],
      };
    } else {
      return {
        address: "",
        status: "🦊 Connect to Metamask using the button below",
      };
    }
  } catch (err) {
    return {
      address: "",
      status: "😥 " + err.message,
    };
  }
};

export const mintNFT = async (quantity) => {
  if (!quantity || quantity <= 0 || quantity > 20) {
    return {
      status: "❗ You can only mint 1 to 20 Baby Ghosts per transaction",
    };
  }

  const chainId = await window.ethereum.request({ method: 'eth_chainId' });

  if (chainId !== '0x1') {
    return {
      status: "❗ You need to select Ethereum Mainnet Network in Metamask to mint Baby Ghosts",
    };
  }

  let walletAddress = null;

  if (window.ethereum.selectedAddress) {
    walletAddress = window.ethereum.selectedAddress;
  } else {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      walletAddress = addressArray[0];

    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  }

  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  let price = await window.contract.methods.price().call();
  let mint = window.contract.methods.mint(walletAddress, quantity).encodeABI();
  let total = price * quantity;

  const balance = await web3.eth.getBalance(walletAddress);
  if (balance <= total) {
    return {
      status: "😥 You don't have enough money in your wallet."
    };
  }

  const transactionParameters = {
    from: walletAddress,
    to: contractAddress,
    value: web3.utils.toHex(total),
    data: mint,
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });

    window.gtag('event', 'Mint', {
      'event_category': 'Baby Ghosts Website',
      'event_label': 'https://etherscan.io/tx/' + txHash,
      'value': quantity
    });
    window.fbq('track', 'Purchase');

    return {
      status: "✅ Congrats! You have minted " + quantity + " Baby Ghosts 👻"
    };
  } catch (error) {
    return {
      status: "😥 Something went wrong: " + error.message
    };
  }
};

export const currentMinted = async () => {
  window.contract = await new web3.eth.Contract(contractABI, contractAddress);

  let total = await window.contract.methods.MAX_TOKENS().call();
  let supply = await window.contract.methods.totalSupply().call();

  return {
    actualTokens: Number(supply),
    maxTokens: Number(total)
  }
}
