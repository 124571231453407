import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import {
  connectWallet,
  getCurrentWalletConnected,
  mintNFT,
  currentMinted
} from "./util/interact.js";
import React from 'react';

const Minter = () => {
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [actualTokens, setActualTokens] = useState("0");
  const [maxTokens, setMaxTokens] = useState("10 000");

  useEffect(() => {
    async function fetchData() {
      const { address, status } = await getCurrentWalletConnected();

      setWallet(address);
      setStatus(status);

      // addWalletListener();
      window.initAnchors();

      // if (window.ethereum) {
      //   connectWalletPressed();
      // }

      // checkCurrentMinted();
    }
    fetchData();
  }, []);

  const checkCurrentMinted = async () => {
    const { actualTokens, maxTokens } = await currentMinted();
    setActualTokens(actualTokens.toLocaleString());
    setMaxTokens(maxTokens.toLocaleString());
  }

  const addWalletListener = () => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using button below");
        }
      });
    } else {
      if (isMobile) {
        var searchParams = new URLSearchParams(window.location.search);

        return setStatus(
          <span>
            <button type="button" className="btn-purple"
              onClick={() => {
                if (searchParams.toString()) {
                  window.open("https://metamask.app.link/dapp/babyghosts.com/?" + searchParams.toString());
                } else {
                  window.open("https://metamask.app.link/dapp/babyghosts.com");
                }
              }}>
                Open Metamask
            </button>
          </span>
        );
      }

      setStatus(
        <span>
          <span className="banner-text" style={{ marginBottom: "13px" }}>You need to install <a href="https://metamask.io/download.html" rel="noreferrer" target="_blank">Metamask</a></span>
        </span>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    const { status } = await mintNFT(window.$('#mint-range').val());
    setStatus(status);
  };

  const onOpenSeaPressed = async () => {
    window.gtag('event', 'OpenSea', {
      'event_category': 'Baby Ghosts Website'
    });
  };

  const soldOut = true;

  if (soldOut) {
    return (
      <div className="form">
        <a onClick={onOpenSeaPressed} href="https://opensea.io/collection/babyghosts?search[sortAscending]=true&search[sortBy]=PRICE&search[toggles][0]=BUY_NOW" className="btn-primary" target="_blank" rel="noreferrer"><span className="btn-inner">SOLD OUT - BUY ON OPENSEA <span className="icon-opensea1 icon"></span></span></a>
        <span className="form-txt"><span style={{ color: "#ff6265" }}>10,000</span> / 10,000 MINTED</span>
      </div>
    );
  }

  if (!window.ethereum) {
    return (
      <form className="form">
        <span className="price"><span className="red">Mint Price</span> is 0.05 ETH</span>
        <p>Mint a Baby Ghost using your <strong>Ethereum Wallet</strong></p>

        <p id="status" style={{ marginTop: "-25px" }}>{status}</p>

        <span className="form-txt"><span className="purple"><strong>{actualTokens}</strong></span> / {maxTokens} MINTED</span>
      </form>
    );
  }

  return (
      <form className="form">
        <span className="price"><span className="red">Mint Price</span> is 0.05 ETH</span>
        <p>Mint a Baby Ghost using your <strong>Ethereum Wallet</strong></p>
        <div className="range-holder">
          <input id="mint-range" type="range" min="1" max="20" defaultValue="2"></input>
        </div>

        <p id="status" style={{ marginBottom: "25px" }}>{status}</p>

        {walletAddress.length > 0 ? (
          <div>
            <button id="mint-button" type="button" className="btn-purple" onClick={onMintPressed}>
              Mint <span data-value>2</span> Baby Ghosts
            </button>
          </div>
        ) : (
          <button type="button" className="btn-purple" onClick={connectWalletPressed}>Connect Metamask</button>
        )}

        <span className="form-txt"><span className="purple"><strong>{actualTokens}</strong></span> / {maxTokens} MINTED</span>
      </form>
  );
};

export default Minter;
